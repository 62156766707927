.footer {
  background-color: $primary-color;
  color: $white;
  padding: 20px 0;
  // margin-top: 40px;
  color: #fff;

  @include media($lg) {
    // margin-top: 80px;
    padding: 40px 0;
  }

  p {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-align: right;
    color: #e5e5e5;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  .nav-link {
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    opacity: 0.65;

    &:hover {
      color: $white;
    }
  }
}

.social-media {
  .btn {
    @include media($sm) {
      padding: 8px;
    }
  }
}

.whatsapp-iocn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url("../images/whatsapp-icon.png") center no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: -99999px;
  z-index: 1;
}
