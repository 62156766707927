// Paths
$font-path: "/fonts";
$icomoon-font-path: "/fonts/icomoon/fonts";
$images-path: "../img";

$primary-color: #1d4ed8;
$text-color: #666666;

$white: #ffffff;
$black: #000000;
$halfBlack: #333333;
// $Black: #666666;
$green: #4caf50;
$red: #bc4545;
$blue: #4599F7;
$blue-dark: #1d4ed8;
$lighBlue: #f3f7fd;
$yellow: #ffcc32;
$grey: #6a6a6a;
$silver: #f2f4f6;
$orange: #f25621;
$pink: #ae4b84;

//Breakpoints
//$xs : 767px;
$xsSmall: 360px;
$xxs: 420px;
$xs: 576px; // was 769px but for iPhoneX Landscape it made 815px
$sm: 768px;
$md: 1024px; // was 770px but for iPhoneX Landscape it made 815px
$lg: 1280px;
$xl: 1366px;
$xxl: 1800px;
