:root {
  --orange: #ff5722;
  --blue: #147ec2;
  --bs-font-sans-serif: "Inter", sans-serif;
  --primary-color: #0278CA;
  --black: #000000;
  --white: #ffffff;  
  font-optical-sizing: auto;
}

body {
  padding-top: 110px;

  @include media($sm) {
    padding-top: 126px;
  }
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @media (min-width: 1400px) {
    max-width: 1280px;
  }
}

.bg-blue {
  background-color: $primary-color;
}

.bg-light-blue {
  background-color: #F4F9FF;
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-outline {
  border: 1px solid #ced4da;

  &:hover {
    border-color: $blue;
    color: $blue;
  }
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black;
}

.slick-dots li {
  height: 13px;
  width: auto;
  margin: 0 3px;

  button {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #EAEEFA;
    border: 1px solid #EAEEFA;

    &::before {
      display: none;
    }
  }

  &.slick-active button {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    width: 30px;
    border-radius: 5px;
    // margin-right: 10px;
  }
}

.slick-dotted-top {
  .slick-dots {
    bottom: inherit;
    top: -58px;
    text-align: right;
  }
}

.link-text {
  color: $black;

  &:hover {
    color: $blue;
  }
}

.scroll-theme {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #262e35;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #262e35;
  }
}

/* Custom CSS for error message styling */
// .error-message {
//   color: red;
//   font-size: 0.9em;
//   display: none;
// }
// .is-invalid {
//   border-color: red;
// }
