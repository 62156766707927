.our-story-sec {
  padding: 120px 0 40px;

  @include media($md) {
    padding: 60px 0;
  }

  .btn-call {
    @include media($sm, max) {
      left: 0;
    }
  }
}

.head-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
  color: #113166;

  @include media($sm) {
    font-size: 40px;
  }
  @include media($lg) {
    font-size: 50px;
  }
}

.sub-title {
  font-size: 18px;
  font-weight: normal;
  color: #666666;
  // max-width: 720px;
  // margin: 0 auto;

  @include media($sm) {
    font-size: 24px;
  }
}

.ads-logo {
  margin: 0 15px;
}

.ose-para {
  max-width: 840px;
  text-align: center;
  margin: 0 auto;

  font-size: 22px;
}

// .para-1 {
//   font-size: 22px;
//   font-weight: 600;
//   color: $halfBlack;

//   @include media($md) {
//     font-size: 32px;
//   }
// }

// .para-2 {
//   color: #5d6180;

//   @include media($md) {
//     font-size: 20px;
//   }
// }

// .quote-box {
//   display: flex;
//   gap: 1rem;
//   max-width: 564px;
//   margin-top: 45px;

//   @include media($md) {
//     gap: 32px;
//   }
// }

// .quote-photo {
//   flex-basis: auto;
//   flex: 0 0 80px;
// }

// .quote-content {
//   position: relative;

//   p {
//     font-style: italic;
//     margin-bottom: 10px;
//     line-height: 1.2;

//     @include media($sm, max) {
//       font-size: 14px;
//     }
//   }

//   h5 {
//     font-size: 12px;

//     b {
//       font-weight: 600;
//     }
//   }
// }

// .quote-mark {
//   position: absolute;
//   bottom: 35px;
//   right: 0;
//   font-size: 100px;
//   color: $green;

//   @include media($md) {
//     bottom: -5px;
//     right: -50px;
//   }
// }

// .expo-sec {
//   margin-top: 50px;
//   padding: 40px 0 0 0;
//   border-top: 1px solid #cecfd3;
//   position: relative;

//   @include media($md) {
//     padding: 40px 60px 0 0;
//   }
// }

// .expo-slider {
//   .slick-next,
//   .slick-prev {
//     width: 49px;
//     height: 49px;

//     &::after {
//       content: "";
//       display: inline-block;
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 49px;
//       height: 49px;

//       background: url("../images/previous-arrow_grey.png") center no-repeat;
//       background-size: contain;

//       // @include media($md) {
//       //   bottom: -139px;
//       //   right: -5px;
//       //   width: 110px;
//       //   height: 110px;
//       // }
//     }

//     &::before {
//       display: none;
//     }
//   }

//   .slick-prev {
//     left: inherit;
//     right: -54px;
//     top: 80px;

//     &::after {
//       background: url("../images/previous-arrow_grey.png") center no-repeat;
//       background-size: contain;
//     }

//     &:hover {
//       &::after {
//         background: url("../images/previous-arrow_blue.png") center no-repeat;
//         background-size: contain;
//       }
//     }
//   }

//   .slick-next {
//     right: -54px;
//     top: 24px;

//     &::after {
//       background: url("../images/next-arrow_grey.png") center no-repeat;
//       background-size: contain;
//     }

//     &:hover {
//       &::after {
//         background: url("../images/next-arrow_blue.png") center no-repeat;
//         background-size: contain;
//       }
//     }
//   }
// }

// .expo-inner {
//   display: flex;
//   align-items: center;
//   gap: 20px;
// }

// .expo-card {
//   h2 {
//     font-size: 14px;
//     font-weight: 600;
//     color: $green;
//   }

//   p {
//     color: #5d6180;
//   }
// }

// .pp-block {
//   h2 {
//     font-size: calc(1.2rem + 0.2vw);

//     &:not(:first-child) {
//       margin-top: calc(1.325rem + 0.9vw);
//     }
//   }
// }

// .service-card {
//   border: 0;
//   border-radius: 0;

//   picture {
//     overflow: hidden;
//     border-top-left-radius: 40px;
//     border-top-right-radius: 40px;
//   }

//   .card-img-top {
//     transition: all 0.3s ease;
//   }

//   .card-body {
//     margin-top: -90px;
//     background: white;
//     border-radius: 40px;
//     box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
//     position: relative;
//     padding: 30px;

//     @include media($sm) {
//       margin-top: -30px;
//       padding: 20px;
//     }

//     @include media($md) {
//       padding: 50px 46px;
//     }
//   }

//   .card-title {
//     font-size: 20px;
//     font-weight: 700;

//     @include media($md) {
//       font-size: 28px;
//     }
//   }

//   .card-title,
//   p {
//     color: $halfBlack;
//   }

//   &:hover {
//     .card-img-top {
//       transform: scale(1.1);
//     }
//   }

//   + .call-box {
//     @include media($sm) {
//       .btn-call {
//         left: 0;
//       }
//     }
//   }
// }

// .red-icon {
//   width: 60px;
//   height: 60px;
//   background: $primary-color url("../images/data-sharing-icon.svg") center
//     no-repeat;
//   background-size: 30px;
//   font-size: 0;
//   border-radius: 20px;
//   margin-bottom: 10px;

//   @include media($md) {
//     width: 87px;
//     height: 87px;
//     background-size: 38px 38px;
//     border-radius: 36px;
//   }

//   &.laptop {
//     background-image: url("../images/laptop-icon.svg");
//   }

//   &.services {
//     background-image: url("../images/calendar-icon.svg");
//   }
// }

// .call-box {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   padding-top: 40px;

//   h4 {
//     font-weight: 700;
//     font-size: 18px;
//     color: $halfBlack;

//     @include media($md) {
//       font-size: 20px;
//     }
//   }

//   .btn-call {
//     &::after {
//       top: 10px;
//       left: inherit;
//       right: -55px;
//       transform: rotate(186deg);
//     }
//   }
// }
