// .offer-strip {
//   background-color: #071232;
//   color: $white;
//   padding: 10px 0;
//   font-weight: 600;

//   p {
//     font-size: 13px;

//     @include media($lg) {
//       font-size: 18px;
//     }
//   }

//   span {
//     color: $green;
//   }

//   b {
//     font-weight: 900;
//   }

//   em {
//     font-style: normal;
//     font-weight: 900;
//   }
// }

.masthead {
  // background: rgb(29, 78, 216);
  // // background: linear-gradient(90deg, rgba(29,78,216,1) 0%, rgba(75,17,155,1) 21%, rgba(17,129,103,1) 48%, rgba(102,17,69,1) 80%, rgba(17,49,102,1) 100%);
  // background: linear-gradient(
  //   90deg,
  //   #1d4ed8 0%,
  //   #4b119b 30.54%,
  //   #118167 48.28%,
  //   #661145 83.05%,
  //   #113166 100%,
  //   #00c308 100%
  // );
  z-index: 1;
  display: flex;
  justify-content: center;
  position: relative;
  // margin-bottom: 1.85rem;
  padding: 30px 0;

  @include media($lg) {
    padding: 60px 0 0;
  }

  h1 {
    font-weight: bold;
    color: #1b2e4c;
    // max-width: 500px;
    font-size: 24px;

    b {
      font-weight: 900;
    }

    @include media($md) {
      font-size: 32px;
    }

    @include media($lg) {
      font-size: 65px;
    }
  }

  p {
    color: #1B2E4C;
    font-size: 20px;
    // max-width: 480px;

    @include media($lg) {
      font-size: 18px;
    }

    span {
      font-weight: 600;
    }
  }

  ul.masthead-list {
    padding-left: 0;

    li {
      position: relative;
      list-style: none;
      font-size: 20px;
      line-height: 1.4;
      padding-left: 38px;
      color: #1b2e4c;

      @include media($lg) {
        font-size: 22px;
      }

      &:not(:last-child) {
        margin-bottom: 18px;
      }

      &::before {
        content: "";
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: url("../images/checked-icon_blue.svg") center no-repeat;
        background-size: 50%;
        position: absolute;
        left: 0;
        top: -2px;
      }
    }
  }

  .btn-call {
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: $blue-dark;
    border-color: $blue-dark;
    border-radius: 12px;
    // margin-left: 0;

    @include media($lg) {
      width: 166px;
      height: 60px;
    }

    &.btn-download {
      margin-left: 32px;
      width: auto;
    }
  }

  .btn-talk {
    background-color: transparent;

    &:hover,
    &:active,
    &:focus-visible {
      color: $white;
      background-color: $blue-dark;
    }
  }

  // .masthead-img {
  //   @include media($lg) {
  //     margin-top: -50px;
  //   }
  // }
}

// .masthead-left {
//   @include media($lg) {
//     padding-top: 30px;
//   }
// }

// .masthead-slider {
//   .slick-dots li button {
//     background-color: #293861;
//     border: 1px solid #293861;
//   }

//   .slick-dots li.slick-active button {
//       border: 1px solid $primary-color;
//       background-color: $primary-color;
//   }
// }

// .btn-link-red {
//   font-size: 14px;
//   font-weight: 700;
//   color: $primary-color;
//   text-decoration: none;
//   display: inline-flex;
//   align-items: center;
//   gap: 5px;

//   &::after {
//     content: "";
//     display: inline-block;
//     width: 9px;
//     height: 9px;
//     background: url("../images/arrow-down-icon.svg") center no-repeat;
//     background-size: contain;
//   }

//   &:hover {
//     color: $primary-color;
//   }
// }

// .happy-customer {
//   position: absolute;
//   bottom: -30px;
//   left: 50%;
//   transform: translateX(-50%);

//   .hc-inner {
//     background-color: $white;
//     border-radius: 36px;
//     padding: 26px 20px;
//     display: inline-flex;
//     align-items: center;
//     gap: 10px;
//     font-size: 20px;
//     font-weight: 700;
//     color: $halfBlack;
//     position: relative;
//     z-index: 1;
//     box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
//   }

//   &::after {
//     content: "";
//     display: inline-block;
//     width: 72px;
//     height: 72px;
//     background: url("../images/gray-dots.svg") center no-repeat;
//     background-size: contain;
//     position: absolute;
//     top: -27px;
//     left: -24px;
//   }
// }

// .hc-2 {
//   position: relative;
//   left: inherit;
//   bottom: 0;
//   transform: none;
//   max-width: 465px;
//   margin: 50px auto;

//   &::after {
//     background: url("../images/red-dots-box.svg") center no-repeat;
//     background-size: contain;
//   }
// }

// .services-masthead {
//   height: 80vh;
//   margin-bottom: 100px;

//   @include media($sm) {
//     height: 340px;
//   }
// }

// .avail-us-box {
//   position: relative;
// }

// .btn-service {
//   border-radius: 29.5px;
//   background: #fff;
//   font-weight: 700;
//   font-size: 15px;
//   letter-spacing: -0.01em;
//   line-height: 1.4;
//   color: $black;
//   padding: 8px 11px;
//   position: absolute;
//   top: 15px;
//   left: 0;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 10px;

//   @include media($sm) {
//     font-size: 17px;
//     padding: 16px 22px;
//     width: 228px;
//     height: 59px;
//     top: -30px;
//   }

//   @include media($lg) {
//     top: 15px;
//   }

//   &::after {
//     content: "";
//     display: inline-block;
//     width: 12px;
//     height: 12px;
//     background: url("../images/top-right-icon.svg") center no-repeat;
//     background-size: contain;
//     transition: all 0.5s ease-in-out;

//     @include media($sm) {
//       font-size: 17px;
//       width: 18.62px;
//       height: 18.62px;
//     }
//   }

//   &:hover,
//   &:active,
//   &:focus-visible,
//   &:first-child:active {
//     background: $white;
//     color: $primary-color;
//     border-color: $white;

//     &::after {
//       transform: rotate(45deg);
//     }
//   }
// }

// .about-masthead {
//   background-image: url("../images/bg-img.jpg");
//   background-size: cover;
//   padding-bottom: 240px;

//   .btn-service {
//     top: 20px;
//     left: inherit;
//     right: 0;
//   }
// }
