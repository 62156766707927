.our-services-sec {
  padding: 120px 0 40px;
  // background-color: rgba($color: #2b85f2, $alpha: 0.05);

  @include media($md) {
    padding: 120px 0;
  }
}

.testi-slider {
  .slick-track {
    display: flex;
  }

  .slick-slide {
    margin: 0 7.5px;
    display: flex;
    height: auto;
  }

  .slick-list {
    margin: 0 -7.5px;
    padding: 10px 0;
    padding-left: 0px !important; // half partial
  }

  // .slick-next,
  // .slick-prev {
  //   width: 31px;
  //   height: 31px;
  //   background: url("../images/icons/back-arrow.svg") center no-repeat;
  //   background-size: contain;
  //   position: absolute;
  //   top: -26px;
  //   &::before {
  //     display: none;
  //   }
  // }

  // .slick-prev {
  //   left: initial;
  //   right: 45px;

  //   @include media($md) {
  //     right: 115px;
  //   }
  // }

  // .slick-next {
  //   background: url("../images/icons/next-arrow.svg") center no-repeat;
  //   background-size: contain;
  //   right: 0;

  //   @include media($md) {
  //     right: 75px;
  //   }
  // }
}

.test-item {
  .card {
    background-color: transparent;
  }
  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .card-text {
    color: $halfBlack;
  }
  .client-name {
    color: #666;

    b {
      color: $halfBlack;
    }
  }
}

.rating {
  padding-left: 0;
  display: flex;
  gap: 5px;

  li {
    list-style: none;
    background: url("../images/star_blue.svg") center no-repeat;
    background-size: 100%;
    font-size: 0;
    width: 15px;
    height: 15px;
    display: inline-block;
  }
}
