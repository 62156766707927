.plans-and-pricing-sec {
  padding: 120px 0 40px;
  // background-color: #16213F;
  // color: $white;

  @include media($md) {
    padding: 120px 0;
  }
}

.pap-block {
  max-width: 1050px;
  margin: 40px auto 0;
}

.pap-card {
  // margin-bottom: 40px;
  border-radius: 20px;
  padding: 30px 25px;
  border: 1px solid $primary-color;
  height: 100%;

  @include media($md) {
    padding: 60px 45px;
  }

  &.bg-blue {
    position: relative;

    .card-title {
      color: $white;
    }

    p {
      color: $white;
    }

    ul {
      li {
        color: #fff8f8;
      }
    }

    h3 span {
      color: $white;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -66px;
      left: -58px;
      width: 250px;
      height: 250px;
      background: url("../images/blue-box.png") center no-repeat;
      background-size: 140%;
      // border-radius: 20px;
      z-index: -1;
    }
  }

  &.bg-light-blue {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -66px;
      right: -28px;
      width: 250px;
      height: 250px;
      background: url("../images/pink-box.png") center no-repeat;
      background-size: 145%;
      // border-radius: 20px;
      z-index: -1;
    }

    .btn-talk {
      background-color: $blue-dark;
      color: $white;
    }
  }

  // .card-body {
  //   padding-top: 40px;
  // }
  .card-title {
    font-weight: 600;
    font-size: 18px;

    // margin-bottom: 20px;

    @include media($md) {
      font-size: 30px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 36px;
    margin: 50px 0 30px;
    display: flex;
    align-items: center;
    gap: 42px;

    @include media($md) {
      font-size: 66px;
    }

    span {
      font-size: 18px;
      font-weight: 400;
    }
  }

  p {
    font-weight: 400;
    line-height: 1.4;

    @include media($md) {
      font-size: 18px;
    }
  }

  ul {
    font-size: 14px;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 20px;
      // font-weight: 400;
      font-size: 14px;

      @include media($md) {
        font-size: 16px;
      }

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #f4ebff url("../images/checked.svg") center no-repeat;
        background-size: 50%;

        // @include media($md) {
        //   bottom: -139px;
        //   right: -5px;
        //   width: 110px;
        //   height: 110px;
        // }
      }
    }
  }

  .btn-talk {
    height: 70px;
    border-radius: 12px;
    background: $white;
    font-weight: 600;
    font-size: 1rem;
    color: $primary-color;
    margin-bottom: 42px;

    @include media($md) {
      font-size: 18px;
    }
  }

  .btn-link {
    text-decoration: none;
    color: #161c2d;
    display: inline-block;
  }
}
