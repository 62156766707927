.help-us-sec {
  padding: 120px 0 40px;
  // background-color: #f1f8fe;

  @include media($md) {
    padding: 120px 0;
  }
}

.huthy-para {
  max-width: 540px;
  font-size: 22px;
  line-height: 1.6;
  color: #666;

  b {
    color: $black;
  }
}

.cu-img {
  border-radius: 10px 0 0 10px;
}

.contact-list {
  font-size: 14px;
  padding: 0;
  list-style: none;
  margin-top: 60px;

  li {
    position: relative;
    padding-left: calc(48px + 15px);
    margin-bottom: 40px;
    // font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: $halfBlack;

    @include media($md) {
      font-size: 20px;
    }

    a {
      color: $halfBlack;
      text-decoration: none;
    }

    // @include media($md) {
    //   font-size: 16px;
    // }

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #f4ebff url("../images/checked.svg") center no-repeat;
      background-size: 50%;

      // @include media($md) {
      //   bottom: -139px;
      //   right: -5px;
      //   width: 110px;
      //   height: 110px;
      // }
    }

    &.phone {
      &::before {
        background: url("../images/call.svg") center no-repeat;
        background-size: contain;
      }
    }

    &.mail {
      &::before {
        background: url("../images/email.svg") center no-repeat;
        background-size: contain;
      }
    }

    &.website {
      &::before {
        background: url("../images/website.svg") center no-repeat;
        background-size: contain;
      }
    }
  }
}

.cu-group {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: $lighBlue;
    background-size: contain;
  }
}

.cpm-card {
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    position: relative;
    padding-left: calc(48px + 18px);
    min-height: 48px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    @include media($md) {
      font-size: 18px;
    }

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: url("../images/card.svg") center no-repeat;
      background-size: contain;
    }
  }

  .card-logos {
    position: relative;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 20px;
      width: 124px;
      height: 24px;
      background: url("../images/cc-card-logos.png") center no-repeat;
      background-size: contain;
    }

    .form-control {
      padding-right: 160px;
    }
  }
}

.cpm-form {
  background-color: $lighBlue;
  padding: 24px 15px;

  .form-check-label {
    font-size: 14px;
  }

  textarea.form-control {
    min-height: 100px;
  }

  .btn-call {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($md) {
      height: 60px;
    }
  }
}

.cpm-text {
  font-size: 18px;
  line-height: 1.8;
  text-align: center;

  b {
    font-weight: 700;
  }

  @include media($md) {
    font-size: 24px;
  }
}
