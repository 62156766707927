.why-should-sec {
  padding: 120px 0 40px;
  background-color: rgba($color: #2b85f2, $alpha: 0.05);

  @include media($md) {
    padding: 120px 0;
  }

  .border-end {
    // border-inline-end-style: dashed;
    border-right-style: dashed !important;
    border-color: #707070;
  }
}

.ws-card {
  border-color: transparent;
  background-color: transparent;
  height: 100%;
  text-align: center;

  .card-title {
    font-weight: 300;
    font-size: 45px;
    line-height: 34px;
    // text-align: center;
    color: $primary-color;
    margin-bottom: 20px;

    @include media($md) {
      font-size: 60px;
      margin-bottom: 40px;
    }

    @include media($lg) {
      font-size: 90px;
      margin-bottom: 50px;
    }
  }

  .card-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5;
    // text-align: left;
    color: #666;

    @include media($md) {
      font-size: 20px;
    }
    @include media($lg) {
      font-size: 22px;
    }

    span {
      color: $primary-color;
    }
  }
}

.who-are-we-sec {
  padding: 120px 0 40px;

  @include media($md) {
    padding: 120px 0;
  }
}

.waw-card {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    background: $primary-color;
    position: absolute;
    top: 0;
    right: 100%;
  }

  .card-body {
    background-color: $primary-color;
    color: $white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.waw-para-1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.5;
  color: #000;
}
.waw-para-2 {
  font-size: 18px;
  line-height: 1.5;
  color: #666;
}

.sec-common {
  padding: 120px 0 40px;

  @include media($md) {
    padding: 120px 0;
  }
}

.u-wrapper {
  background: url("../images/bg-2.png") right no-repeat;
  background-size: 80%;
  padding: 45px 0;
}

.understanding-card {
  border-radius: 35px;
  // background: #eaeefa;
  box-shadow: 0px 12px 16px rgba(219, 220, 222, 0.77);
  // padding: 10px;
  border: 10px solid #eaeefa;
  height: 100%;

  .card-body {
    padding: 0;
  }

  .card-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: #1b2e4c;
    padding: 30px;
  }

  .u-img {
    // padding-right: 40px;
    border-radius: 0 10px 10px 0;
    width: 90%;
    // margin: 35px 0;
  }

  .card-text {
    padding: 30px;
    font-weight: normal;
    font-size: 22px;
    line-height: 1.4;
    color: #1b2e4c;
    padding: 30px;
  }
}

.si-sec {
  position: relative;
  padding-bottom: 0;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 80%;
    background-color: rgba($color: #2b85f2, $alpha: 0.05);
    position: absolute;
    top: 0;
    left: 0;
  }
  .para-text {
    font-weight: normal;
    font-size: 22px;
    line-height: 1.4;
    color: #1b2e4c;
  }
}

.si-img {
  margin-top: -60px;
}
